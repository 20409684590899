<template>
    <div class="container" v-cloak>

        <div class="jumbotron">

            <h1>Вход</h1>

            <form class="form-group" method="POST" action="" @submit.prevent="login()">

                <label for="email">Email</label>
                <div class="control-group">
                    <input v-model="email" id="email" type="email" class="form-control" placeholder="your.email@gmail.com" required>
                </div>
                <br>

                <label for="password">Парола</label>
                <div class="control-group">
                    <input v-model="password" id="password" type="password" class="form-control" placeholder="Парола" required>
                </div>
                <br>

                <input type="submit" class="btn btn-primary" value="Влез">

            </form>

        </div>

    </div>

</template>

<script>
    import { mapActions } from 'vuex'
    import router from '@/router'

    export default {
        name: 'Login',
        data() {
            return {
                email: '',
                password: '',
            }
        },
        methods: {
            ...mapActions(['authLogin']),
            async login() {
                await this.authLogin({
                    email: this.email,
                    password: this.password
                })
            }
        },
        created() {
            if (this.$store.state.auth.isTokenSet) {
                router.push({
                    name: 'CatalogCategories',
                    params: {
                        id: null
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .jumbotron {
        line-height: 20px;
        max-width: 500px;
        margin: 30px auto;
        padding: 50px;
        background: #fff;
    }
</style>